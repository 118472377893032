<template>
  <section id="about">
    <div class="tab-indicator" :class="{ show: selectedTab == 2 }">
      <div
        v-on:click="changeTimeLine('1')"
        class="indicator ind-1"
        @click="activeIndicator = 1"
        :class="{ active: activeIndicator == 1 }"
      ></div>
      <div
        v-on:click="changeTimeLine('2')"
        class="indicator ind-2"
        @click="activeIndicator = 2"
        :class="{ active: activeIndicator == 2 }"
      ></div>
      <div
        v-on:click="changeTimeLine('3')"
        class="indicator ind-3"
        @click="activeIndicator = 3"
        :class="{ active: activeIndicator == 3 }"
      ></div>
    </div>
    <h1>About</h1>
    <div class="tab-nav">
      <ul>
        <li @click="selectedTab = 1" :class="{ active: selectedTab == 1 }">
          <p v-on:click="changeTab('1')" class="tab-nav-item">Life</p>
        </li>
        <li @click="selectedTab = 2" :class="{ active: selectedTab == 2 }">
          <p v-on:click="changeTab('2')" class="tab-nav-item">Education</p>
        </li>
        <li @click="selectedTab = 3" :class="{ active: selectedTab == 3 }">
          <p v-on:click="changeTab('3')" class="tab-nav-item">Experience</p>
        </li>
        <li v-if="this.$themeName == 'default'" class="slide"></li>
        <li
          v-if="this.$themeName == 'christmas'"
          class="slide slide-kerst"
        ></li>
        <li
          v-if="this.$themeName == 'halloween'"
          class="slide slide-halloween"
        ></li>
        <li v-if="this.$themeName == 'fall'" class="slide slide-fall"></li>
      </ul>
    </div>
    <div class="mobile-tab-indicator" :class="{ show: selectedTab == 2 }">
      <div class="arrow-up" v-on:click="moveTimeLineUp()">
        <img src="/assets/standard/down-chevron.png" />
      </div>
    </div>
    <div class="tabs">
      <div class="tab" id="life">
        <div class="life-text">
          <p>Hi, ik ben Xander.</p>
          <p>
            Ik ben geboren in Helmond, Nederland. Ik ben voor het eerst begonnen
            met het maken van websites toen ik in het 4e jaar zat op de
            middelbare school. Hier had ik het vak informatica gekozen bij mijn
            vakkenpakket. Toen ik op het MBO verder ging met het designen en
            bouwen van websites wist ik dat ik hier meer mee wilde gaan doen. Ik
            heb hier extra tijd en aandacht ingestoken om mezelf te ontwikkelen
            op dit gebied. Echter ben ik nog lang niet waar ik zou willen zijn
            en ben ik zeer nieuwsgiering naar hoe sommige websites zijn
            opgebouwd.
          </p>
          <p>
            Vanaf mijn 4e ben ik begonnen met zwemmen en hier ben ik niet meer
            mee gestopt. Nu ben ik wedstrijdzwemmer bij de Helmondse
            watervrienden. Niet alleen is het een leuke sport, maar ik heb ook
            een hele gezellige club! Verder stop ik mijn energie in fitness en
            vind ik het leuk om te fotograferen, af en toe te drummen en in de
            zomer maanden ga ik ook regelmatig skaten.
          </p>
          <!-- <img
            class="about-img"
            v-if="this.$themeName == 'christmas'"
            src="/assets/winter/undraw_gifts_re_97j6 1.svg"
          />
          <img
            v-if="this.$themeName == 'halloween'"
            class="about-img"
            src="/halloween/undraw_witch.svg"
          />
          <img
            v-if="this.$themeName == 'fall'"
            class="about-img"
            src="/assets/fall/undraw_fall_is_coming_yl-0-x.svg"
          /> -->
        </div>
        <img class="life-img" src="/assets/standard/xander.jpeg" />
      </div>
      <div class="tab" id="education">
        <div class="education education-1">
          <div class="timeline">
            <p class="timeline-start-date">Februari 2020</p>
            <p class="timeline-end-date">Heden</p>
          </div>
          <div class="timeline-title">
            <h3>MBO in Applicatie- en mediaontwikkelaar</h3>
            <h3>ROC Ter AA– Helmond</h3>
          </div>
          <img
            v-if="this.$themeName == 'default'"
            src="/assets/standard/undraw_programming_-2-svr1.svg"
          />
          <img
            v-if="this.$themeName == 'halloween'"
            src="/halloween/undraw_programming_-2-svr1.svg"
          />
          <img
            v-if="this.$themeName == 'christmas'"
            src="/assets/winter/undraw_programming_-2-svr1.svg"
          />
          <img
            v-if="this.$themeName == 'fall'"
            src="/assets/fall/undraw_programming_-2-svr1.svg"
          />
        </div>
        <div class="education education-2">
          <div class="timeline">
            <p class="timeline-start-date">September 2019</p>
            <p class="timeline-end-date">Januari 2020</p>
          </div>
          <div class="timeline-title">
            <h3>HBO in ICT (niet afgerond)</h3>
            <h3>Avans Hogeschool – Den Bosch</h3>
          </div>
          <img
            v-if="this.$themeName == 'default'"
            src="/assets/standard/undraw_coding_re_iv621.svg"
          />
          <img
            v-if="this.$themeName == 'halloween'"
            src="/halloween/undraw_coding_re_iv622.svg"
          />
          <img
            v-if="this.$themeName == 'christmas'"
            src="/assets/winter/undraw_coding_re_iv62.svg"
          />
          <img
            v-if="this.$themeName == 'fall'"
            src="/assets/fall/undraw_coding_re_iv622.svg"
          />
        </div>
        <div class="education education-3">
          <div class="timeline">
            <p class="timeline-start-date">September 2014</p>
            <p class="timeline-end-date">Mei 2019</p>
          </div>
          <div class="timeline-title">
            <h3>HAVO in N&T</h3>
            <h3>Dr.-Knippenbergcollege – Helmond</h3>
          </div>
          <img
            v-if="this.$themeName == 'default'"
            src="/assets/standard/undraw_road_to_knowledge_m8s01.svg"
          />
          <img
            v-if="this.$themeName == 'halloween'"
            src="/halloween/undraw_road_to_knowledge_m8s02.svg"
          />
          <img
            v-if="this.$themeName == 'christmas'"
            src="/assets/winter/undraw_road_to_knowledge_m8s0.svg"
          />
          <img
            v-if="this.$themeName == 'fall'"
            src="/assets/fall/undraw_road_to_knowledge_m8s02.svg"
          />
        </div>
      </div>
      <div class="tab" id="experience">
        <div class="companies">
          <div class="company com-1" :class="{ active: activeCompany == 1 }">
            <img src="/assets/standard/unfolded.svg" />
            <p>
              Mijn eerste stage heb ik mogen afronden bij Unfolded in Eindhoven.
              Ik ben hier bezig geweest met front-end development. De eerste
              week heb ik een cursus gevold via Udemy.com (
              <a href="https://www.udemy.com/course/advanced-css-and-sass/"
                >https://www.udemy.com/course/advanced-css-and-sass/</a
              >) Deze cursus raad ik voor iedereen die start met front-end
              development aan. Na de cursus ben ik aan de slag gegaan met mijn
              eerste opdracht voor een klant. Hier heb ik dan ook veel van
              geleerd. Ik moest aan de hand van het design dat werd gemaakt door
              een collega gaan coderen. Dit gebeurde voornamelijk in Nuxt.js,
              maar er werd ook gebruik gemaakt van Laravel. In het begin kreeg
              ik veel feedback op het werk dat ik opleverde. Dit had vaak te
              maken met grootte van lettertype en spacing op de pagina. Door
              veel feeback te krijgen en te overleggen ging het maken van de
              pagina's telkens beter. Ik heb ook een beetje kennis gemaakt met
              Craft CMS.
            </p>
          </div>
          <div class="company com-2" :class="{ active: activeCompany == 2 }">
            <img src="/assets/standard/stofloos-logos_1.png" />
            <p>
              Ik ga mijn afstudeerstage lopen bij Stofloos in Eindhoven. Deze
              stage zal plaatsvinden van 7 februari 2022 tot en met 29 juni
              2022. Tijdens deze stage zal ik ook mijn afstudeeropdracht gaan
              maken. To be continued...
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-tab-indicator" :class="{ show: selectedTab == 2 }">
      <div class="arrow-down" v-on:click="moveTimeLineDown()">
        <img src="/assets/standard/down-chevron.png" />
      </div>
    </div>
    <div
      class="tab-indicator tab-indicator-companies"
      :class="{ show: selectedTab == 3 }"
    >
      <div
        @click="activeCompany = 1"
        class="indicator"
        :class="{ active: activeCompany == 1 }"
      ></div>
      <div
        class="indicator"
        @click="activeCompany = 2"
        :class="{ active: activeCompany == 2 }"
      ></div>
    </div>
  </section>
</template>

<script>
export default {
  name: "About",
  data: function () {
    return {
      selectedTab: 1,
      activeIndicator: 1,
      activeCompany: 1,
    };
  },
  methods: {
    changeTab(n) {
      if (n == 1) {
        document.querySelector(".tabs").style.transform = "translateX(0px)";
      }
      if (n == 2) {
        document.querySelector(".tabs").style.transform = "translateX(-100vw)";
      }
      if (n == 3) {
        document.querySelector(".tabs").style.transform = "translateX(-200vw )";
      }
    },
    changeTimeLine(n) {
      if (n == 1) {
        document.querySelector("#education").style.transform = "translateY(0)";
      }
      if (n == 2) {
        document.querySelector("#education").style.transform =
          "translateY(-100vh)";
      }
      if (n == 3) {
        document.querySelector("#education").style.transform =
          "translateY(-200vh)";
      }
    },
    moveTimeLineDown() {
      if (
        document.querySelector("#education").style.transform ==
        "translateY(-100vh)"
      ) {
        document.querySelector("#education").style.transform =
          "translateY(-200vh)";

        document.querySelector(".arrow-down").style.display = "none";
      } else {
        document.querySelector(".arrow-up").style.display = "block";
        document.querySelector("#education").style.transform =
          "translateY(-100vh)";
      }
    },
    moveTimeLineUp() {
      if (
        document.querySelector("#education").style.transform ==
        "translateY(-100vh)"
      ) {
        document.querySelector("#education").style.transform = "translateY(0)";
        document.querySelector(".arrow-up").style.display = "none";
      } else if (
        document.querySelector("#education").style.transform ==
        "translateY(-200vh)"
      ) {
        document.querySelector("#education").style.transform =
          "translateY(-100vh)";
        document.querySelector(".arrow-down").style.display = "block";
      }
    },
  },
};
</script>

<style scoped>
#about {
  background: #fff;
  position: relative;
  overflow: hidden;
}

.tab-nav {
  z-index: 1000;
  margin-bottom: 1rem;
}

.tab-nav ul {
  display: flex;
  color: #a5a5a5;
  font-weight: bold;
  position: relative;
  width: max-content;
  padding: 0;
}

.tab-nav ul li {
  list-style: none;
  width: 150px;
}

.tab-nav p {
  cursor: pointer;
  position: relative;
  margin-bottom: 0;
  transition: all 0.2s;
}

.tab-nav p:hover {
  color: #000;
}

.slide {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 50px !important;
  height: 3px;
  background: #63dde2;
  transition: all 0.5s;
}

.slide-kerst {
  background: #c80e48;
}

.slide-halloween {
  background: #d35721;
}

.slide-fall {
  background: #dba72e;
}

.tab-nav li:nth-child(1).active ~ .slide {
  left: 0;
}

.tab-nav li:nth-child(2).active ~ .slide {
  left: 150px;
}

.tab-nav li:nth-child(3).active ~ .slide {
  left: 300px;
}

.tabs {
  width: 300vw;
  height: 100%;
  display: flex;
  transition: all 0.5s;
}

.tab {
  width: calc(100vw - 20rem);
  margin-right: 20rem;
}

#life {
  display: flex;
  justify-content: space-between;
}

.life-text {
  position: relative;
  max-width: 60rem;
}

.life-img {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  margin-left: 1rem;
  transition: all 0.3s;
  cursor: pointer;
}

.life-img:hover {
  transform: translateY(-5px) scale(0.95);
  -webkit-box-shadow: 0px 11px 10px 5px rgba(0, 0, 0, 0.14);
  box-shadow: 0px 11px 10px 5px rgba(0, 0, 0, 0.14);
}

.tab-text {
  margin-right: 5rem;
}

#education {
  position: relative;
  transition: all 0.5s;
}

.education {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20rem;
  position: relative;
}

#education::-webkit-scrollbar {
  display: none;
}

.tab-indicator {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 10rem;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  z-index: 100;
}

.tab-indicator.show {
  visibility: visible;
}

.indicator {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #000;
  cursor: pointer;
}

.indicator.active {
  background: #000;
}

.indicator:not(:last-child) {
  margin-bottom: 0.5rem;
}

.mobile-tab-indicator {
  display: none;
  position: relative;
  width: 100%;
  left: 0;
  z-index: 10;
}

.mobile-tab-indicator img {
  width: 30px;
  height: 30px;
}

.arrow-down {
  position: absolute;
  bottom: 0rem;
  left: 50%;
  transform: translateX(-50%);
}

.arrow-up {
  position: absolute;
  top: 0rem;
  left: 50%;
  transform: translateX(-50%) rotate(180deg);
  display: none;
}

.mobile-tab-indicator p {
  font-size: 2rem;
}

.education img {
  height: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.education-1 {
  position: relative;
}

.education-2 {
  position: relative;
}
.education-3 {
  position: relative;
}

.timeline {
  height: 70%;
  width: 2px;
  background: #000;
  position: relative;
}

.timeline::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  height: 10px;
  width: 10px;
  background: #fff;
  transform: translateX(-50%);
  border-radius: 50%;
  border: 2px solid #000;
}

.timeline::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 10px;
  width: 10px;
  background: #fff;
  transform: translateX(-50%);
  border-radius: 50%;
  border: 2px solid #000;
}

.timeline-title {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  text-align: center;
  width: 100%;
}

.timeline-start-date {
  position: absolute;
  top: 0;
  transform: translate(10%, -10%);
  width: 200px;
  height: 1rem;
}

.timeline-end-date {
  position: absolute;
  bottom: 0;
  transform: translate(10%, 50%);
  width: 200px;
  height: 1rem;
}

#experience {
  position: relative;
}

#experience .companies {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.company {
  transition: all 0.5s;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.company img {
  width: 15rem;
  margin-bottom: 1rem;
}

.company p {
  max-width: 60rem;
}

.com-1 {
  height: 100%;
  transform: translateY(-100vh);
}

.com-2 {
  height: 100%;
  transform: translateY(100vh);
}

.company.active {
  transform: translate(0vh, 0vh);
}

/* theme images */

.about-img {
  position: absolute;
  bottom: 0rem;
  left: 0;
  width: 10rem;
}

@media (max-width: 480px) {
  .tab-nav ul li {
    width: 100px;
  }

  .tab-nav li:nth-child(1).active ~ .slide {
    left: 0;
  }

  .tab-nav li:nth-child(2).active ~ .slide {
    left: 100px;
  }

  .tab-nav li:nth-child(3).active ~ .slide {
    left: 200px;
  }

  .tabs {
    height: 60vh;
  }

  .tab {
    width: calc(100vw - 4rem);
    margin-right: 4rem;
  }

  #life {
    flex-direction: column;
    overflow-y: scroll;
  }

  .life-img {
    display: none;
  }

  .tab-indicator {
    display: none;
  }

  .tab-indicator-companies {
    display: flex;
    justify-content: center;
    position: relative;
    flex-direction: row;
    top: 0;
    right: 0;
    margin-top: 1rem;
    height: 15px;
    width: 100%;
  }

  .indicator:not(:last-child) {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }

  .mobile-tab-indicator.show {
    display: flex;
  }

  .education {
    margin-bottom: 40vh;
  }

  .education img {
    display: none;
  }

  .timeline-title {
    top: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
  }

  .company {
    overflow-y: scroll;
  }

  .company img {
    width: 10rem;
  }

  /* theme images */

  .about-img {
    display: none;
  }
}
</style>
