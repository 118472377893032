<template>
  <div id="app">
    <Navigation />
    <Introduction />
    <About />
    <Work />
    <Projects />
    <Footer />
  </div>
</template>

<script>
import Navigation from "./components/Nav-bar.vue";
import Introduction from "./components/Introduction.vue";
import About from "./components/About.vue";
import Work from "./components/Work.vue";
import Projects from "./components/Projects.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    Navigation,
    Introduction,
    About,
    Work,
    Projects,
    Footer,
  },
  data: function () {
    return {
      activeTheme: 1,
    };
  },
};
</script>

<style>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html,
body {
  font-family: "Roboto Slab", serif;
}

#app {
  scroll-snap-type: y mandatory;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

section {
  scroll-snap-align: start;
  padding: 10rem;
  height: 100vh;
}

h1 {
  font-size: 48px;
  margin: 0;
  margin-bottom: 20px;
}
h2 {
  font-size: 36px;
}
h3 {
  font-size: 24px;
  margin: 0;
}

p {
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 1rem;
}

a {
  font-size: 18px;
  color: #a5a5a5;
  text-decoration: none;
  transition: all 0.2s;
}

a:hover {
  color: #000;
}

@media (max-width: 480px) {
  section {
    padding: 5rem 2rem;
    scroll-snap-align: unset;
  }

  h1 {
    font-size: 36px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 18px;
  }
  p {
    font-size: 14px;
  }
  a {
    font-size: 14px;
  }
}
</style>
