<template>
  <section id="work">
    <h1>Work</h1>
    <div v-for="(job, index) in jobs" :key="index" class="job">
      <h3>{{ job.name }}</h3>
      <p>{{ job.location }}</p>
      <p>{{ job.startYear }} - {{ job.endYear }}</p>
      <p class="description">
        {{ job.description }}
      </p>
    </div>
  </section>
</template>

<script>
import workData from "/public/work.json";
export default {
  name: "Work",
  data() {
    return {
      jobs: workData,
    };
  },
};
</script>

<style scoped>
#work {
  background: #f5f5f5;
  /* height: calc(100vh - 20rem); */
}

.job {
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  background: #fff;
  border-radius: 15px;
  margin-bottom: 10px;
}

.job h3 {
  width: 15rem;
}

.job p {
  margin: 0 2rem;
}

@media (max-width: 480px) {
  .job {
    flex-direction: column;
  }

  .job h3 {
    width: max-content;
  }

  .job p {
    width: auto;
  }

  .description {
    width: auto;
    text-align: center;
  }
}
</style>
