import Vue from "vue";
import App from "./App.vue";

Vue.config.productionTip = false;
// fall, halloween, christmas, default
Vue.prototype.$themeName = "default";

new Vue({
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
