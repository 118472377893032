<template>
  <section id="projects">
    <h1>Projects</h1>
    <div class="projects-container">
      <div class="card" v-for="(project, index) in projects" :key="index">
        <a target="_blank" v-bind:href="project.website">
          <div class="card-content">
            <div class="card-content-top">
              <h3>{{ project.title }}</h3>
              <p>{{ project.description }}</p>
            </div>
            <div class="card-content-bottom">
              <div class="wrapper">
                <img src="/assets/standard/Schedule.svg" />
                <p>{{ project.startDate }} - {{ project.endDate }}</p>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
    <img
      v-if="this.$themeName == 'christmas'"
      class="projects-img"
      src="/assets/winter/undraw_snow_games_ohkc.svg"
    />
  </section>
</template>

<script>
import projectsData from "/public/projects.json";
export default {
  name: "Projects",
  data() {
    return {
      projects: projectsData,
    };
  },
};
</script>

<style scoped>
#projects {
  background: #fff;
  position: relative;
}

.projects-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  overflow-y: auto;
}

.projects-container a {
  color: #000;
}

.card {
  background: #f5f5f5;
  max-width: 500px;
  height: 250px;
  border-radius: 15px;
  padding: 2rem 3rem;
  position: relative;
  transition: all 0.3s;
}

/* .card:hover {
  transform: scale(0.97);
} */

.card-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card-content-bottom {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 6rem);
  padding: 2rem 3rem;
}

.wrapper {
  display: flex;
}

.wrapper p {
  margin: 0 0.5rem;
}

.project-members {
  position: absolute;
  right: 3rem;
}

.project-member {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-left: -1rem;
  border: 1px solid #fff;
}

/* theme img */
.projects-img {
  width: 20rem;
  position: absolute;
  bottom: 0rem;
  left: 10rem;
  z-index: 0;
}
@media (max-width: 480px) {
  .card {
    width: auto;
    height: 150px;
    overflow: hidden;
    padding: 1rem 2rem;
  }

  .card-content-bottom {
    padding: 1rem 2rem;
  }

  .wrapper img {
    display: none;
  }

  .wrapper p {
    margin: 0;
  }

  .project-members {
    right: 0;
  }

  .projects-img {
    display: none;
  }
}
</style>
