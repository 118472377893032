<template>
  <div class="nav-bar">
    <a href="#intro"><p class="logo">xanderjanse.</p></a>
    <img
      v-on:click="toggleMobileNav(1)"
      class="mobile-menu-toggle"
      src="/assets/standard/menu-button-of-three-horizontal-lines.png"
    />
    <div class="mobile-navigation">
      <div class="close" v-on:click="toggleMobileNav(2)">
        <img src="/assets/standard/close.png" />
      </div>
      <div class="mobile-nav-bar-items">
        <a href="#intro" v-on:click="toggleMobileNav(2)">Home</a>
        <a href="#about" v-on:click="toggleMobileNav(2)">About</a>
        <a href="#work" v-on:click="toggleMobileNav(2)">Work</a>
        <a href="#projects" v-on:click="toggleMobileNav(2)">Projects</a>
        <a href="#footer" v-on:click="toggleMobileNav(2)">Contact</a>
      </div>
    </div>

    <div class="nav-bar-items">
      <a href="#about"><div>About</div></a>
      <a href="#work"><div>Work</div></a>
      <a href="#projects"><div>Projects</div></a>
      <a href="#footer"><div>Contact</div></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Nav-bar",
  methods: {
    toggleMobileNav(n) {
      if (n == 1) {
        document.querySelector(".mobile-navigation").classList.add("active");
      }
      if (n == 2) {
        document.querySelector(".mobile-navigation").classList.remove("active");
      }
    },
  },
};
</script>

<style scoped>
/* navigation for mobile */
.mobile-menu-toggle {
  height: 16px;
  width: 16px;
  position: absolute;
  top: 1rem;
  right: 2rem;
  display: none;
}
.mobile-navigation {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background: #ffffff;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(100vw);
  transition: all 0.5s ease-in-out;
}
.mobile-navigation.active {
  transform: translateX(0);
}
.close {
  position: absolute;
  top: 1rem;
  right: 2rem;
  width: 16px;
  height: 16px;
}

.close img {
  width: 100%;
  height: 100%;
}
.mobile-nav-bar-items {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.mobile-nav-bar-items a {
  font-size: 2rem;
}

.mobile-nav-bar-items a:not(:last-child) {
  margin-bottom: 2rem;
}

/* navigation for desktop */
.nav-bar {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  padding: 0 40px;
  width: 100%;
  background: #fff;
  z-index: 1000;
}

.logo {
  margin-top: 1rem;
}

.nav-bar-items {
  display: flex;
  align-items: center;
  gap: 40px;
}

a {
  text-decoration: none;
  color: #a5a5a5;
  font-weight: bold;
}

@media (max-width: 480px) {
  .nav-bar {
    padding: 0;
    width: 100vw;
    padding: 0 2rem;
  }
  .nav-bar-items {
    display: none;
  }

  .mobile-menu-toggle {
    display: block;
  }
}
</style>
